import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {AuthService} from '../services/auth.services';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NavController} from "@ionic/angular";
import {MessageServices} from "../services/message.services";
import {Helper} from "../models/helper.models";

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        public auth: AuthService,
        private messageServices: MessageServices,
        private navCtrl: NavController,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.auth.getToken() != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }

        return next.handle(request).pipe(tap(() => {

        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                    return;
                }

                // Helper.clearLogin();
                this.navCtrl.navigateRoot(['./login']);
                this.messageServices.dismissLoading();

            }
        }));
    }
}

import {Injectable} from '@angular/core';
import {Helper} from '../models/helper.models';
import {LoginResponseModels} from '../models/api-user/login-response.models';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor() {
    }

    public getToken(): any {
        return Helper.getMeResponse()?.accessToken;
    }
}

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {DomainService} from "../services/domain.service";
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DomainGuard implements CanActivate {
    constructor(
        private router: Router,
        private domainService: DomainService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let domain = route.params;
        let indicadoPor = route.queryParams['indicadoPor'];

        if (indicadoPor) {
            window.localStorage.setItem("indicado_por", indicadoPor);
        }

        if (domain && domain.segment == "cadastro") {
            window.localStorage.removeItem("dados_cadastro");
            return true;
        }

        return this.domainService.checkDomain(domain.segment).pipe(
            map((res: any) => {
                window.localStorage.setItem("dados_cadastro", JSON.stringify(res.dados));
                return true;
            }),
            catchError((error) => {
                return of(this.router.createUrlTree(['/cadastro']));
            })
        );


    }
}

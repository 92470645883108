import {LoadingController, ToastController} from '@ionic/angular';
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MessageServices {

  private isLoading: boolean;

  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
  ) {
  }

  presentToast(body: string, position?: string, duration?: number) {
    this.toastController.create({
      message: body,
      duration: (duration && duration > 0) ? duration : 2000,
      position: (position && (position === 'top' || position === 'middle')) ? position : 'bottom'
    }).then(toast => toast.present());
  }

  async presentLoading(body: string) {
    this.isLoading = true;
    return await this.loadingController.create({message: body}).then(overlay => {
      overlay.present().then(() => {
        if (!this.isLoading) {
          try {
            overlay.dismiss().then(() => console.log('loading aborted'));
          } catch (error) {
            console.log(error);
          }
        }
      });
    });
  }

  async dismissLoading() {
    this.isLoading = false;
    try {
      return await this.loadingController.dismiss();
    } catch (error) {
      return null;
    }
  }
}

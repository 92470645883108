import {InjectionToken} from "@angular/core";
import {Constants} from "src/models/contants.models";

export let APP_CONFIG = new InjectionToken<AppConfig>("app.config");

export interface AppConfig {
  availableLanguages: Array<{ code: string, name: string }>;
  defaultThemeMode: string;
  demoMode: boolean;
}

export const BaseAppConfig: AppConfig = {
  availableLanguages: [
    {
      code: 'pt',
      name: 'Português'
    }],
  defaultThemeMode: Constants.THEME_MODE_DARK,
  demoMode: true
};

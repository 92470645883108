import {Constants} from './contants.models';
import {LoginResponseModels} from './api-user/login-response.models';
import {UserModels} from './api-user/user.models';

export class Helper {

  static setUserLoginResponse(loginResponseModels: LoginResponseModels) {
    window.localStorage.setItem(Constants.USER_LOGIN_RESPONSE, JSON.stringify(loginResponseModels));
  }

  static getUserLoginResponse(): LoginResponseModels {
    return Helper.keysToCamel(JSON.parse(window.localStorage.getItem(Constants.USER_LOGIN_RESPONSE)));
  }

  static setMeResponse(user: any) {
    window.localStorage.setItem(Constants.USER_ME_RESPONSE, JSON.stringify(user));
  }

  static getMeResponse(): UserModels {
    return Helper.keysToCamel(JSON.parse(window.localStorage.getItem(Constants.USER_ME_RESPONSE)));
  }

  static clearLogin() {
    window.localStorage.removeItem(Constants.USER_ME_RESPONSE);
    window.localStorage.removeItem(Constants.USER_LOGIN_RESPONSE);
  }

  static toCamel(s) {
    return s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
      .replace('-', '')
      .replace('_', ''));
  };

  static isArray(a) {
    return Array.isArray(a);
  };

  static isObject(o) {
    return o === Object(o) && !Helper.isArray(o) && typeof o !== 'function';
  };

  static keysToCamel(o) {
    if (Helper.isObject(o)) {
      const n = {};

      Object.keys(o)
        .forEach((k) => {
          n[Helper.toCamel(k)] = Helper.keysToCamel(o[k]);
        });

      return n;
    } else if (Helper.isArray(o)) {
      return o.map((i) => Helper.keysToCamel(i));
    }

    return o;
  };

  static toSnake(s) {
    return s.split(/(?=[A-Z])/).join('_').toLowerCase();
  }

  static keysToSnack(o) {
    if (Helper.isObject(o)) {
      const n = {};

      Object.keys(o)
        .forEach((k) => {
          n[Helper.toSnake(k)] = Helper.keysToSnack(o[k]);
        });

      return n;
    } else if (Helper.isArray(o)) {
      return o.map((i) => Helper.keysToSnack(i));
    }

    return o;
  };
}

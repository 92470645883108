// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://api-buy-help-app-backoffice.local:8083/api/cadastro/v1/'
    apiUrl: 'https://api-app-backoffice-sandbox.buyhelp.com.br/api/cadastro/v1/',
};



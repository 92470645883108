import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DomainService {

    constructor(private http: HttpClient) { }

    public checkDomain(domain: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}credenciado/check-domain`, {domain: domain});
    }
}
